<template>
  <div>
    <!-- 会员管理查询弹出层 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="isShowDialog_"
      :modal-append-to-body="double"
      :width="diaWidth"
      :close-on-click-modal="isCloseModal"
      @close="handleCloseDia"
      :append-to-body="isAppend"
    >
      <slot>
        <!-- <div class="diaConten">具体内容</div> -->
      </slot>

      <span v-if="dialogFooter" slot="footer" class="dialog-footer">
        <el-button
          class="diaBtns"
          style="margin-right: 50px"
          v-if="showCacel"
          size="mini"
          @click="diaCancelBtn"
          >关 闭</el-button
        >
        <el-button
          v-if="showConfirm"
          class="diaBtns glo_bgcMainColor"
          type="primary"
          size="mini"
          @click="diaConfirmBtn"
          >{{ confirmText }}</el-button
        >
        <el-button
          v-if="showConfirm1"
          class="diaBtns glo_bgcMainColor"
          type="primary"
          size="mini"
          @click="diaConfirmBtn1"
          >{{ confirmText1 }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isCloseModal: {
      //点击遮罩层是否关闭 false==不关闭
      type: Boolean,
      default: false,
    },
    showCacel: {
      // 是否显示关闭按钮
      type: Boolean,
      default: true,
    },
    showConfirm: {
      //是否显示确定按钮
      type: Boolean,
      default: true,
    },
    showConfirm1: {
      //是否显示确定按钮
      type: Boolean,
      default: false,
    },
    isShowDialog: {
      //弹窗是否展示
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    diaWidth: {
      type: String,
      default: "",
    },
    confirmText: {
      type: String,
      default: "确 定",
    },
    confirmText1: {
      type: String,
      default: "确认调度",
    },
    double: {
      type: Boolean,
      default: true,
    },
    dialogFooter: {
      type: Boolean,
      default: true,
    },
    isAppend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowDialog_: false, //弹窗是否展示
    };
  },
  watch: {
    isShowDialog(a, b) {
      this.isShowDialog_ = a;
    },
  },

  methods: {
    // 关闭弹窗的操作
    handleCloseDia() {
      this.isShowDialog_ = false;
      this.$emit("handleCloseDia");
    },
    // 弹窗确定按钮
    diaConfirmBtn() {
      this.$emit("diaConfirmBtn");
    },
    // 弹窗确定按钮
    diaConfirmBtn1() {
      this.$emit("diaConfirmBtn1");
    },
    // 关闭确定按钮
    diaCancelBtn() {
      this.$emit("diaCancelBtn");
    },
  },
};
</script>

<style scoped lang='scss'>
.diaBtns {
  width: 80px;
  padding: 10px;
}
</style>