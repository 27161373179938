<template>
  <!-- 多选时间 -->
  <div class="container">
    <div class="timeBox queryItemTime">
      <el-select
        class="labers"
        :disabled="timeTypesList.length == 1"
        v-model="timeTypeVal"
        filterable
        @change="timestatus"
        placeholder="请选择时间类型"
      >
        <el-option
          v-for="item in timeTypesList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="right_inpu wtl_timeStyle">
        <el-date-picker
          class="input"
          value-format="yyyy-MM-dd"
          v-model="startTimes"
          type="date"
          placeholder="选择日期"
          @change="queryBtn_ok"
        ></el-date-picker>
        <div style="margin: 0 6px">至</div>
        <el-date-picker
          class="input"
          value-format="yyyy-MM-dd"
          v-model="endTimes"
          type="date"
          placeholder="选择日期"
          @change="queryBtn_ok"
        ></el-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    startTime: {
      type: String,
      default: "",
    },
    endTime: {
      type: String,
      default: "",
    },
    timeType: {
      type: String,
      default: "",
    },
    timeTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      startTimes: "",
      endTimes: "",
      timeTypesList: [],
      timeTypeVal: "", //  时间类型
    };
  },
  created() {},
  mounted() {},
  watch: {
    //
    // startTime(a, b) {
    //     this.startTimes = a || ''
    // },
    // endTime(a, b) {
    //     this.endTimes = a || ''
    // },

    endTime: {
      immediate: true,
      handler: function (a, b) {
        this.endTimes = a || "";
      },
      deep: true, //深度监听
    },
    startTime: {
      immediate: true,
      handler: function (a, b) {
        this.startTimes = a || "";
      },
      deep: true, //深度监听
    },

    //当值第一次绑定的时候，不会执行监听函数。添加 immediate 属性，值为 true。这样在最初绑定值的时候也执行函数
    timeType: {
      immediate: true,
      handler: function (a, b) {
        this.timeTypeVal = a || "0";
      },
      deep: true, //深度监听
    },

    timeTypes: {
      immediate: true,
      handler: function (a, b) {
        this.timeTypesList = a || [];
      },
      deep: true, //深度监听
    },
  },
  methods: {
    timestatus(e) {
      this.$emit("timestatus", e, this.startTimes, this.endTimes);
    },
    queryBtn_ok() {
      this.$emit("queryBtn_ok", this.startTimes, this.endTimes);
    },
  },
};
</script>

<style scoped lang="scss">
$borderColor: #ccc;
$mainColor: #ef7c0a;
.container {
  width: 470px;
  margin-right: 10px;
}

.timeBox {
  display: flex;
  align-items: center;
  border: 1px solid $borderColor;
  height: 30px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 4px;

  /deep/ .el-input__inner {
    height: 30px;
    line-height: 30px;
    border: none;
  }

  .labers {
    width: 130px;
  }

  .right_inpu {
    flex: 1;

    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
  }
}
.timeBox:hover {
  border: 1px solid $mainColor;
  box-shadow: 0px 0px 5px $mainColor;
}

.wtl_timeStyle {
  display: flex;
  align-items: center;
  height: 28px;
}
// }
</style>

<!-- 
    父组件应用

        <multitime :timeType="timeType" :timeTypes="timeTypes" :startTime="startTime_a" :endTime="endTime_a"
                @timestatus="timestatus" @queryBtn_ok="queryBtn_time">
        </multitime>

        import multitime from "@/components/multiTime.vue";


        startTime_a:'',
        endTime_a:'',
        timeType:'0',
        timeTypes:[
        {
                    value: '0',
                    label: '时间'
               },
        ],



      queryBtn_time(s, e) {
            this.startTime_a = s || ''
            this.endTime_a = e || ''
        },
        timestatus(type, s, e) {
            this.timeType = type || '0'
            this.startTime_a = s || ''
            this.endTime_a = e || ''
        }, 
        
        
        
        -->
