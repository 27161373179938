<template>
  <div ref="queryForm">
    <el-form
      ref="forms"
      :class="More ? 'glo_form hasMoreParam' : 'glo_form'"
      label-position="left"
    >
      <span v-for="(item, index) in F_config" :key="index">
        <span v-if="item.isShow">
          <el-form-item
            class="queryItems"
            v-if="item.textBoxType === 'input'"
            :label="item.conditonKeyShow"
          >
            <!-- v-model="F_Data[item.conditonKey]" -->
            <el-input
              v-model="item.conditonVal"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item
            class="queryItems"
            v-if="item.textBoxType === 'input_more'"
            :label="item.conditonKeyShow"
          >
            <!-- v-model="F_Data[item.conditonKey]" -->
            <el-input
              v-model="item.conditonVal"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            class="queryItems months"
            v-if="item.textBoxType === 'date_month'"
            :label="item.conditonKeyShow"
          >
            <!-- v-model="F_Data[item.conditonKey]" -->
            <el-date-picker
              prefix-icon="''"
              value-format="yyyy-MM"
              v-model="item.conditonVal"
              type="month"
              placeholder="选择月"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            class="queryItems"
            v-if="item.textBoxType === 'select'"
            :label="item.conditonKeyShow"
          >
            <!-- v-model="F_Data[item.conditonKey]" -->

            <el-select
              v-model="item.conditonVal"
              clearable
              remote
              filterable
              :remote-method="
                (e) => {
                  remoteMethod(e, item, index);
                }
              "
              @visible-change="focusMethod($event, item, index)"
              @clear="remoteMethod('', item, index)"
            >
              <!-- @focus="
                (e) => {
                  focusMethod(item, index);
                }
              " -->
              <el-option
                v-for="(option, num) in item.selectOptions"
                :label="option[item.dropdownShowField]"
                :value="option[item.dropdownSubmitField]"
                :key="num"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            class="queryItems"
            v-if="item.textBoxType === 'select_object'"
            :label="item.conditonKeyShow"
          >
            <el-select
              v-model="item.conditonVal"
              :disabled="
                item.conditonKey == 'objectName' &&
                !F_config[index - 1].conditonVal
              "
              clearable
              remote
              filterable
              @visible-change="getList($event, item, index)"
              @change="
                changeObjVal($event, item, index, item.dropdownSubmitField)
              "
              :remote-method="
                (e) => {
                  remoteMethodOBJ(e, item, index);
                }
              "
            >
              <el-option
                v-for="(option, num) in item.selectOptions"
                :label="option[item.dropdownShowField]"
                :value="option[item.dropdownSubmitField]"
                :key="num"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            class="queryItemsTimes"
            v-if="item.textBoxType === 'datetime'"
          >
            <multitime
              :timeType="item.timeType"
              :timeTypes="item.timeTypes"
              :startTime="item.startTime_a"
              :endTime="item.endTime_a"
              @timestatus="
                (type, s, e) => {
                  timestatus(type, s, e, index);
                }
              "
              @queryBtn_ok="
                (s, e) => {
                  queryBtn_time(s, e, index);
                }
              "
            ></multitime>
          </el-form-item>
          <el-form-item
            class="queryItemsTimes"
            v-if="item.textBoxType === 'date'"
          >
            <multitime
              :timeType="item.timeType"
              :timeTypes="item.timeTypes"
              :startTime="item.startTime_a"
              :endTime="item.endTime_a"
              @timestatus="
                (type, s, e) => {
                  timestatus(type, s, e, index);
                }
              "
              @queryBtn_ok="
                (s, e) => {
                  queryBtn_time(s, e, index);
                }
              "
            ></multitime>
          </el-form-item>
        </span>
      </span>
    </el-form>
  </div>
</template>
<script>
import multitime from "@/components/multiTimeDT.vue";
export default {
  components: { multitime },
  props: {
    formConfig: {
      type: Array,
    },
    formData: {
      type: Object,
    },
    More: {
      type: Boolean,
    },
  },
  emits: ["changeCallback", "EnterClick"],
  data() {
    return {
      F_config: this.formConfig,
      F_Data: this.formData,
    };
  },
  watch: {
    formConfig: {
      handler(newValue, oldValue) {
        this.F_config = newValue;
      },
      deep: true, // 默认值是 false，代表是否深度监听
    },
  },
  methods: {
    // 选择结算对象
    changeObjVal(val, item, index, key) {
      if (item.conditonKey == "objectStatusConfDictId") {
        this.F_config[index + 1].conditonVal = "";

        let list = item.selectOptions || [];

        list.forEach((element) => {
          if (element[key] == val) {
            this.F_config[index].key = element.key;
          }
        });
      }
    },
    // 获取结算对象/类型下拉列表
    async getList(e, item, index) {
      this.F_config[index].selectOptions = [];
      if (e) {
        let arrs = [
          {
            conditonKey: item.dropdownShowField,
            conditonVal: "",
          },
        ];
        if (item.conditonKey == "objectName") {
          // item.conditonKey == 'objectName' && !F_config[index-1].conditonVal
          arrs.push({
            conditonKey: "key",
            conditonVal: this.F_config[index - 1].key,
          });
        }
        await this.$API
          .runPageReport({
            conditionReqs: arrs,
            number: item.dropdownApiNumber,
            pageStart: 1,
            pageTotal: 20,
          })
          .then((res) => {
            let datas = res.data.result.datas.data || [];
            // this.F_config[index].selectOptions = datas;
            let fdata = JSON.parse(JSON.stringify(this.F_config));
            fdata[index].selectOptions = datas;
            this.F_config = fdata;
          });
      }
    },

    // 时间组件 选择时间后触发
    queryBtn_time(s, e, index) {
      // this.F_config[index].startTime_a = `${s} 00:00:00` || "";
      // this.F_config[index].endTime_a = `${e} 23:59:59` || "";
      this.F_config[index].startTime_a = s || "";
      this.F_config[index].endTime_a = e || "";
    },
    // 时间组件 选择类型后触发
    timestatus(type, s, e, index) {
      this.F_config[index].timeType = type || "";
      // this.F_config[index].startTime_a = `${s} 00:00:00` || "";
      // this.F_config[index].endTime_a = `${e} 23:59:59` || "";
      this.F_config[index].startTime_a = s || "";
      this.F_config[index].endTime_a = e || "";
    },
    async focusMethod(type, item, index) {
      if (!type) {
        return false;
      }
      // if (this.F_config[index].selectOptions.length == 0) {
      if (item.conditonKey == "fieldName") {
        // 不分页查询

        this.$API
          .runPageReportDetails({
            number: item.dropdownApiNumber,
            conditionReqs: [
              {
                conditonKey: item.dropdownShowField,
                conditonVal: "",
              },
            ],
          })
          .then((res) => {
            let datas = res.data.result.datas || [];
            this.F_config[index].selectOptions = datas;
          });
      } else {
        // 分页查询
        await this.$API
          .runPageReport({
            conditionReqs: [
              {
                conditonKey: item.dropdownShowField,
                conditonVal: "",
              },
            ],
            number: item.dropdownApiNumber,
            pageStart: 1,
            pageTotal: 100,
          })
          .then((res) => {
            let datas = res.data.result.datas.data || [];
            this.F_config[index].selectOptions = datas;
          });
      }
      // }
    },
    // 远程搜索
    async remoteMethod(e, item, index) {
      await this.$API
        .runPageReport({
          conditionReqs: [
            {
              conditonKey: item.dropdownShowField,
              conditonVal: e,
            },
          ],
          number: item.dropdownApiNumber,
          pageStart: 1,
          pageTotal: 20,
        })
        .then((res) => {
          let datas = res.data.result.datas.data || [];
          this.F_config[index].selectOptions = datas;
        });
      this.$emit("changeCallback", e, name);
    },
    // 远程搜索(结算对象/类型)
    async remoteMethodOBJ(e, item, index) {
      if (item.conditonKey == "objectName") {
        let arrs = [
          {
            conditonKey: item.dropdownShowField,
            conditonVal: e,
          },
        ];
        arrs.push({
          conditonKey: "key",
          conditonVal: this.F_config[index - 1].key,
        });
        // if (item.conditonKey == "objectName") {
        //   arrs.push({
        //     conditonKey: "key",
        //     conditonVal: this.F_config[index - 1].key,
        //   });
        // }
        await this.$API
          .runPageReport({
            conditionReqs: arrs,
            number: item.dropdownApiNumber,
            pageStart: 1,
            pageTotal: 20,
          })
          .then((res) => {
            let datas = res.data.result.datas.data || [];
            this.F_config[index].selectOptions = datas;
          });
      }
    },
    // 滚动条加载更多
    loadmore(e) {
      this.$emit("changeCallback", "", e);
    },
    enterClick() {
      this.$emit("EnterClick");
    },
  },
};
</script>
<style lang="scss" scope>
.months {
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    // width: 210px !important;
    min-width: auto;
    width: auto;
  }

  // .el-input__prefix {
  //   right: 5px !important;
  // }
}
</style>
